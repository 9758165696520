<template>
  <div>
    <div class="card-content">
      <div class="buttons">
        <button
          v-for="(type, index) in options"
          :key="index"
          class="button"
          @click="select(type)"
          :class="buttonColour(type)"
        >
          {{ startCase(type) }}
        </button>
      </div>
    </div>
    <div class="card-footer has-background-white">
      <div class="column is-12">
        <div class="columns is-mobile">
          <div class="column">
            <a v-if="itemCount" class="has-text-danger" @click="clearFilters">
              Reset
            </a>
            <p v-else class="has-text-grey-light">Reset</p>
          </div>
          <div class="column is-narrow">
            <p v-if="values.length">{{ itemCount }} selected</p>
            <p v-else class="has-text-grey-light">No items selected</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { startCase } from 'lodash'
export default {
  name: 'StockviewPricePositionFilter',
  props: {
    filter: {
      type: Object,
      required: true
    }
  },
  data: () => ({ values: [] }),
  mounted() {
    if (this.filters.pricePosition) {
      this.values = this.filters.pricePosition
    }
  },
  computed: {
    ...mapGetters('stockview/listings', ['filters', 'filterOptions']),
    itemCount() {
      const selectedCount = this.values.length
      const optionCount = this.options?.length
      const count = selectedCount < optionCount ? selectedCount : optionCount
      return count === 1 ? '1 item' : `${count} items`
    },
    colours() {
      return {
        PricedWell: 'success',
        ConsiderReview: 'warning',
        OverPriced: 'danger',
        UnderPriced: 'danger'
      }
    },
    options() {
      return this.filterOptions.pricePosition
    }
  },
  methods: {
    startCase,
    ...mapActions('stockview/listings', ['updateFilters']),
    setFilterState() {
      const type = 'pricePosition'
      this.updateFilters({ filters: [{ type, payload: this.values }], count: this.values.length, type })
    },
    track(action, type = null) {
      this.$mxp.track(`stockview_stock_${action}_price_position_filter`, type)
    },
    clearFilters() {
      this.values = []
      this.track('reset')
      this.setFilterState()
    },
    buttonColour(type) {
      return this.values.includes(type) ? `is-${this.colours[type]}` : ''
    },
    select(type) {
      if (this.values.includes(type)) {
        this.track('remove', { type })
        this.values = this.values.filter(element => element !== type)
      } else {
        this.track('add', { type })
        this.values.push(type)
      }

      this.setFilterState()
    }
  }
}
</script>
